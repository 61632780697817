export const environment = {
  production: false,
  useEmulator: false,
  tenantRedirect: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBypJLp0Sa0nH9ZweilUOf8CyYGxxpgd9s',
    authDomain: 'uat.safe-auth.seakglobal.com',
    databaseURL: 'https://safe-global-uat.firebaseio.com',
    projectId: 'safe-global-uat',
    storageBucket: 'safe-global-uat.appspot.com',
    messagingSenderId: '913700315497',
    appId: '1:913700315497:web:612f0cde91d1b3eb8d1df8',
    measurementId: 'G-M8JHQ54TEQ'
  },
  domainBlacklistUrl: 'https://seakglobalpublic.blob.core.windows.net/domain-blacklist/domain-blacklist.txt',
  appEngineUrl: 'https://safe-global-uat.appspot.com/',
  dashboardTitle: 'header.title.safe-console-uat',
  snackBarDuration: { duration: 6000 },
  defaultLocale: 'en-gb',
  locales: ['en-gb', 'cy'],
  consoleUrl: 'https://uat.safe-console.seakglobal.com/',
  portalRoot: 'safe-business.seakglobal.com',
  consoleRoot: 'safe-console.seakglobal.com',
  consoleDomain: 'uat.safe-console.seakglobal.com',
  version: require('../../package.json').version,
};
